import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import BlogStyle from './blog-post.module.css'
import Share from '../components/share'


class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const imageURL = post.frontmatter.image.publicURL
    const { previous, next } = this.props.pageContext
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} image={imageURL} keywords={post.frontmatter.keyword ? post.frontmatter.keyword.split(', ') : null}
        />
        <div className={BlogStyle.page}></div>
        <div className={BlogStyle.pageContainer}>
          <div className={BlogStyle.cover} style={{backgroundImage: `url(${imageURL})`}} />
          <div className={BlogStyle.container}>
            <div className={BlogStyle.shareContainerOnBanner}>
                <Share 
                  socialConfig= {{
                    twitterHandle: '@sonic_inst',
                    config: {
                      url: `${siteUrl}${post.fields.slug}`,
                      title: post.frontmatter.title,
                    },
                  }}
                  tags={['MO-Band', 'SonicInstruments', 'MusicTech']}
                />
            </div>
            <h1 className={BlogStyle.title}>{post.frontmatter.title}</h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
                marginTop: rhythm(-1),
                textAlign: "center",
              }}
            >
              {post.frontmatter.date} | {post.frontmatter.arthur}
            </p>
            <div className={BlogStyle.shareContainer}>
              <Share 
                socialConfig= {{
                  twitterHandle: '@sonic_inst',
                  config: {
                    url: `${siteUrl}${post.fields.slug}`,
                    title: post.frontmatter.title,
                  },
                }}
                subtitle={post.frontmatter.subtitle}
                tags={['MO-Band', 'SonicInstruments', 'MusicTech']}
              />
              
            </div>
            <div class={BlogStyle.content}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
            <div
              style={{
                marginBottom: rhythm(1),
              }}
            />

            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                margin: 0,
                marginTop: '3rem',
                padding: 0,
              }}
            >
              <li style={{fontSize: '0.8em'}}>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li style={{fontSize: '0.8em'}}>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const blogQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        arthur
        image {
          publicURL
        }
        keyword
      }
    }
  }
`
